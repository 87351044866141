let content = {
    homepage_title: 'Document Collection Software for Free | Collect Bulk Documents & Data from your clients faster',
    product_title: 'Product | Request & Collect Documents & Data | Document collection Automation',
    use_cases_title: 'Use cases | Document Checklist Tool, Document Management System',
    pricing_title: 'Pricing | Collect & Validate Documents | Document Request Software',
    faq_title: 'FAQs | Document Collection Made Easy | Document Collection Tool',
    contact_title: 'Contact us | Document Collection Online',
    login_title: 'Login | Document Collection App',
    sign_up_title: 'Sign up for Free Trial | Collect Documents from clients',
    careers_title: 'Career | Content gathering Tool | Best Document Collection Software',
    integration_title: 'Integration | Document Collection Portal',
    term_condition_title: 'Terms & Conditions | Document request Software',
    privacy_policy_title: 'Privacy Policy | How to collect documents online',
    client_portal_title: 'Client Portal - One single portal to upload Documents & communication',

    homepage_meta_desc: 'DocuTick.com allows you to collect list of Documents and Data from your clients. Request Documents to your clients, collect and manage Documents on our portal. Accept or Reject Documents and store on DocuTick or Sync it into could Apps.',
    product_meta_desc: 'Amazing features with list of features for collecting bulk Documents from clients. Create Request with list of Documents required to collect, send envelope to client. Once client upload documents, manage on portal. All in one Document collection system.',
    use_cases_meta_desc: 'Explore DocuTick to collect documents, files and data from your clients. Mortgage Brokers, Lawyers, Accountants, Auditors, HR Managers, Education institutes, Immigration Agents & Financial planners use DocuTick to collect Documents from their clients. The simple and faster way to request and collect documents from your clients.',
    pricing_meta_desc: 'Start with free 10 Envelopes. Our prices are very competitive than others, pay for request when client upload document in the portal.',
    faq_meta_desc: 'Browse our list of FAQs for frequently asked questions. Please contact our support team if you don\'t find your query in FAQs.',
    contact_meta_desc: 'Contact us if you have any queries or questions for DocuTick Software.',
    login_meta_desc: 'Login to DocuTick for creating online Documents collection request. Manage Documents with Approval and Rejection system.',
    sign_up_meta_desc: 'Sign up for Free Trail. No credit card required. No commitment. Explore software before you use.',
    careers_meta_desc: 'Browse list of jobs on our site and Apply. We are always looking for talented IT staff.',
    integration_meta_desc: 'We are continuously doing integration with other providers for better experience.',
    term_condition_meta_desc: 'Go through Terms and conditon while you are using DocuTick.',
    privacy_policy_meta_desc: 'Please read our privacy policy for better understanding of how we collect your data and information.',

    homepage_meta_keyword: 'Collect Documents, Collect Data, Collect information',
    product_meta_keyword: 'Request files, collect files',
    use_cases_meta_keyword: 'Collect Documents, collect files, collect information',
    pricing_meta_keyword: 'Validate Documents, Request Documents',
    faq_meta_keyword: 'Data collection, Document collection Tool',
    contact_meta_keyword: 'Files collection online',
    login_meta_keyword: 'gather docs online, collect docs online',
    sign_up_meta_keyword: 'Best Docs collection software',
    careers_meta_keyword: 'Collect Documents online, Web portal for Documents collection',
    integration_meta_keyword: 'Document portal for collection',
    term_condition_meta_keyword: 'Request & collect files, collect information',
    privacy_policy_meta_keyword: 'Documents online, Collect Docs App',

    home_start_scratch: 'Create an Envelope from Scratch.',
    home_use_template: 'Create an Envelope from Pre made Template.',
    env_envelope_name: 'An Envelope is a set of requests for Documents, Information, and Digital Signatures. Please enter a name for your Envelope for easy reference.',
    env_due_date: 'Set the deadline for when the requested documents, information, or digital signatures should be submitted.',
    env_client_message: 'This message is displayed in the client portal messaging section. It comes with a default message that you can customize to fit your needs.',
    env_save_exit: 'Save & Exit will save your envelope into Manage - Draft section.',
    env_recipient_detail: 'Enter the information for the recipients from whom you want to collect documents, information, or digital signatures. You can either search for recipients already saved or add a new recipient by providing their name, email, and mobile number. Company details are optional.',
    env_add_recipient: 'Add new recipients in your Envelope.',
    env_subject: 'Enter the subject line for the email that will be sent to recipients. A default subject line is provided, which you can customize as needed.',
    env_email_text: 'Enter the content of the email that will be sent to recipients. A default email content is provided, which you can customize to suit your needs.',
    env_add_doc_template: 'Add Documents in Envelope from Pre made Templates.',
    env_add_document: 'Add a new document request in your Envelope.',
    env_add_data_template: 'Add Data into Envelope from Pre made Templates.',
    env_add_question: 'Design and generate a new form for requesting information.',
    env_sms_type: 'Select the type of SMS notification to be sent to recipients.',
    env_naming_convention: 'After collecting Documents, we will save documents with this name.',
    env_cloud_sync: 'Please click on Settings > Integration and connect to the cloud App first. After connecting the cloud App, you will be able to send documents to cloud Apps.',
    env_send_reminder: 'We will send reminder email to recipients until they finish uploading documents & Data.',
    env_overdue_reminder: 'After Envelope Due Date, We will send reminder email to recipients until they finish uploading documents & Data.',
    env_password: 'Protect your envelope with password.',
    env_reference: 'Give unique reference to envelope for your reference.',
    env_send: 'Clicking on Send Envelope will send email to your recipients, recipients will get a unique link in email to upload documents & Data.',
    env_add_to_template: 'Add this Envelope into Templates and you can use this Envelope again.',
    env_view_envelope: 'View and manage your Envelope.',
    env_create_another: 'Create another Envelope.',

    home_text: 'Select the option to create an Envelope & send request to your clients.',
    notification_envelope_activity: 'Send email to sender by email when Document or information is uploaded on envelope.',
    preference_sync_cloud: 'Sync Envelope Documents to the Cloud',
    preference_sync_cloud_text: 'Sync all Envelope Documents to the Cloud.',
    preference_default_storage: 'Select Default cloud storage',
    preference_default_storage_text: 'Select the default envelope cloud storage for your account.',

    google_drive_success: 'Google drive successfully connected',
    drop_box_success: 'Dropbox successfully connected',
    box_success: 'Box successfully connected',
    oops_message: 'Oops...something went wrong. Please try again.'
};

export const Lang = content;