import React from "react";

function ProductFeatures() {
    return (
        <div className="custom_container  my-5 py-5">
            <div className="row gy-4">
                <div className="col-lg-4 col-sm-12">
                    <div
                        className="d-flex align-items-center align-items-lg-start justify-content-center flex-column h-100 pb-3 pb-lg-0">
                        <div className="px-3 py-2 rounded-3 mb-3"
                             style={{backgroundColor: '#e6f0ff', width: 'fit-content'}}>
                            <p className="text-dark"><b>Why Use</b></p>
                        </div>
                        <h2 className="text-dark text-center text-sm-start display-6">PRODUCT FEATURES</h2>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center"
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/driver-license.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Request Documents</h4>
                            <p className="text-center">Easily create and send document requests to multiple recipients. Track responses in real-time to ensure you promptly receive all required documents securely.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/mail.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Request Information</h4>
                            <p className="text-center">Collect and manage information with customizable forms. Tailor them to specific needs, send them to multiple recipients, and track responses for accurate, streamlined data collection.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="images/website-design.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">E-Signature</h4>
                            <p className="text-center">Sign and manage documents online with Docutick’s E-signature feature. Request E-signatures to contracts and agreements, all from a single, streamlined platform.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/approve.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Approval Workflows</h4>
                            <p className="text-center">Manage approvals by quickly approving or rejecting documents, information, or signatures. Streamline decision-making, track progress, and keep projects on schedule with ease.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/cloud-computing.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Secure Client Portal</h4>
                            <p className="text-center">Let clients securely upload documents, provide information, and sign with a passcode-protected portal, ensuring all actions are confidential and restricted to authorized users.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center"
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/bell.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Due Date Reminders</h4>
                            <p className="text-center">Set due dates and rely on automated reminders to keep tasks on schedule by notifying clients of upcoming deadlines and overdue submissions. Ensure timely completion and reduce delays.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/signature.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Tracking and Audit Trail</h4>
                            <p className="text-center">Monitor and record every action taken on your envelope with detailed tracking and audit trails. Ensure transparency, accountability, and a clear record of all activities for future reference.</p>
                        </div>
                    </div>
                </div>
                
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/staff.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Cloud Storage Integrations</h4>
                            <p className="text-center">Seamlessly connect with Google Drive, OneDrive, Dropbox, and Box for efficient document storage and access. Sync your files automatically and manage them directly from your preferred cloud storage service.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/staff.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Security</h4>
                            <p className="text-center">Protect your data and safeguard your information at every step. Our platform ensures that your sensitive documents and information are secure, with robust measures in place to prevent unauthorized access and breaches.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/staff.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Docutick Templates</h4>
                            <p className="text-center">Quickly collect information and documents using our premade templates. These reusable templates streamline your data collection process, ensuring consistency and efficiency while saving you time.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/staff.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Create Reusable Template</h4>
                            <p className="text-center">Design and save custom templates for repeated use. Streamline your tasks by creating reusable templates for collecting information and documents, ensuring efficiency and consistency every time.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/staff.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Secured Messaging System</h4>
                            <p className="text-center">Communicate seamlessly and keep your clients aligned in real-time with our secured messaging system. Ensure all exchanges are confidential and secure, supporting efficient and private interactions.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/staff.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Roles & Permissions</h4>
                            <p className="text-center">Manage access levels, assign roles, and securely control user actions with precision. Ensure that each user has the appropriate permissions to maintain security and efficiency across your platform.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div
                        className="d-flex align-items-center product_div flex-column p-lg-4 p-sm-3 py-5 px-4 border border-3 rounded-4"
                        ref={(el) => el && el.style.setProperty('border-color', '#d6e7ff', "important")}>
                        <div
                            className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                            style={{height: '73px', width: '73px'}}>
                            <img src="/images/staff.svg" alt="..." style={{maxWidth: '60%'}}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h4 className="text-dark mb-4">Automated File Naming</h4>
                            <p className="text-center">Streamline your workflow with automatic file naming. Ensure consistency and organization by applying predefined naming conventions, making it easier to manage and locate your files quickly and efficiently.</p>
                        </div>
                    </div>
                </div>







            </div>
        </div>
    );
}

export default ProductFeatures;