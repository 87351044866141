import React from "react";
import { Lang } from "../../../lang";

function TermsConditionsOnlyForSign(props) {
  // Accessing the prop value
  const isUpdateMeta = props?.isUpdateMeta;

  if (isUpdateMeta === undefined) {
    document.title = Lang.term_condition_title;
    document.getElementsByTagName("META")[4].content =
      Lang.term_condition_meta_desc;
    document.getElementsByTagName("META")[5].content =
      Lang.term_condition_meta_keyword;
  }

  return (
    <div className="p-0" style={{ minHeight: "calc(100vh - 406px)" }}>
      <div className="title_container px-4 py-5">
        <h1 className="text-dark text-center mb-3">Privacy Policy</h1>
        <p
          className="text-dark text-center"
          style={{ maxWidth: "max-content" }}
        >
          We are extremely concerned to protect your privacy and
          confidentiality. Our policy includes what we can record about you your
          provided information on our website.
        </p>
      </div>

      <div className=" custom_container_portal py-5 px-3">
        <h3 className="text-dark mb-0">
          {" "}
          Terms of Service for Docutick Signature Module
        </h3>
        <p>
          <strong>Effective Date: 01/08/2023</strong>
        </p>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-2">1. Introduction</h3>
        <p>
          Welcome to Docutick's Signature Module. These Terms of Service
          ("Terms") govern your access to and use of the Docutick Signature
          Module services provided by Docutick ("we," "our," or "us"). By
          accessing or using the Signature Module, you agree to comply with and
          be bound by these Terms and our Privacy Policy. If you do not agree
          with these Terms, you must not use the Signature Module.
        </p>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-2">2. Description of Services</h3>
        <p className="mb-3">
          The Docutick Signature Module provides users with the capability to
          upload, manage, and digitally sign documents. This service is designed
          to facilitate secure and efficient document processing and signature
          collection.
        </p>
        <h3 className="text-dark mb-3"> 3. Account Responsibilities</h3>
        <p className="ms-3">
          <strong>3.1 Account Creation and Security</strong>
        </p>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            You must provide accurate and complete information when creating an
            account.
          </li>
          <li>
            You are responsible for maintaining the confidentiality of your
            account credentials.
          </li>
          <li>
            You agree to notify us immediately of any unauthorized use of your
            account or any other breach of security.
          </li>
        </ul>
        <p className="ms-3">
          <strong>3.2 Use of Services</strong>
        </p>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            You agree to use the Signature Module only for lawful purposes.
          </li>
          <li>
            You are responsible for ensuring that all documents uploaded comply
            with applicable laws and do not infringe on the rights of any third
            party.
          </li>
          <li>You must not upload malicious, harmful, or offensive content.</li>
          <li>
            You must not Interfere with or disrupt the operation of the
            Software.
          </li>
          <li>
            You must not Attempt to access or use the Software in a manner not
            explicitly permitted by these Terms.
          </li>
        </ul>
        <p className="ms-3">
          <strong>3.3 User responsibility</strong>
        </p>
        <br />
        <p className="ms-5">
          By utilizing Docutick's electronic signature services, users
          acknowledge and agree to adhere to the following responsibilities:
        </p>
        <br />
        <div className="ms-5">
          <p>
            <strong>A) Control and Responsibility for Document Content:</strong>
          </p>
          <ul className="ms-3 mb-3 mt-3">
            <li>
              Users have exclusive control and responsibility for the content of
              all documents and data they upload or use with Docutick's
              electronic signature services.
            </li>
            <li>
              Users must ensure that the content of their documents is lawful,
              accurate, and appropriate for electronic signatures.
            </li>
          </ul>
          <p>
            <strong>
              B) Compliance with Legal and Regulatory Requirements:
            </strong>
          </p>
          <ul className="ms-3 mb-3 mt-3">
            <li>
              Users are responsible for ensuring that their use of electronic
              signatures complies with all applicable laws and regulations.
            </li>
            <li>
              Certain types of documents, such as wills, trusts, court orders,
              and family law matters, may be excluded from general electronic
              signature laws or subject to specific regulations. Users must
              determine if their documents fall under these categories and
              comply with relevant legal requirements.
            </li>
          </ul>
          <p>
            <strong>C) Appropriateness for Electronic Signatures:</strong>
          </p>
          <ul className="ms-3 mb-3 mt-3">
            <li>
              Users are solely responsible for determining whether their
              documents, agreements, or contracts are suitable for electronic
              signatures.
            </li>
            <li>
              Docutick is not liable for any determination or use of documents
              with electronic signatures.
            </li>
          </ul>
          <p>
            <strong>D) Consumer Protection Compliance:</strong>
          </p>
          <ul className="ms-3 mb-3 mt-3">
            <li>
              Users must ensure compliance with consumer protection laws and
              regulations, which may impose specific requirements for electronic
              transactions involving consumers.
            </li>
            <li>
              Docutick has no obligation to make determinations regarding
              consumer protection compliance or assist with fulfilling any
              related requirements.
            </li>
          </ul>
          <p>
            By accepting these responsibilities, users help maintain the
            integrity and legality of their transactions and ensure the
            effective use of Docutick's electronic signature services.
            Compliance with these responsibilities is crucial for protecting
            both the user's interests and the interests of all parties involved
            in the electronic signature process.
          </p>
        </div>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-2">
          4) Data Collection, Data Processing and Privacy
        </h3>
        <p>
          Our data collection and usage practices are outlined in our{" "}
          <strong>
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </strong>
          , which is incorporated by reference into these Terms. Please review
          our Privacy Policy to understand how we handle your data.
        </p>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            By using the Signature Module, you acknowledge and consent to the
            processing of your information as described in our Privacy Policy.
          </li>
          <li>
            You are considered the data controller for any personal data
            included in the documents you upload and process through the
            Signature Module. We act as a data processor, processing information
            on your behalf according to your instructions. Docutick is not
            responsible for the contents and documents uploaded by the
            recipients.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-0">5) Document Security</h3>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            We implement industry-standard security measures to protect the
            documents you upload to the Signature Module.
          </li>
          <li>
            While we strive to protect your information, we cannot guarantee its
            absolute security. Users are encouraged to back up their documents
            in their own systems and delete from Docutick platform
          </li>
          <li>
            Docutick has implemented Multi-Factor Authentication (MFA) to
            enhance the security of end-user logins. It is the responsibility of
            users to ensure that their authentication credentials, including
            passwords, security codes, and any other forms of authentication
            information, are kept secure and confidential.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-0">6) Intellectual Property</h3>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            All intellectual property rights in the Signature Module and its
            content are owned by Docutick or its licensors.
          </li>
          <li>
            You may not copy, modify, distribute, or reverse engineer any part
            of the Signature Module without our express written consent.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-0">7) Termination and Suspension</h3>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            We reserve the right to suspend or terminate your access to the
            Signature Module at any time, without notice, for conduct that we
            believe violates these Terms or is harmful to other users.
          </li>
          <li>
            Upon termination, your right to use the Signature Module will
            immediately cease.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-0">8) Limitation of Liability</h3>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            To the fullest extent permitted by law, Docutick shall not be liable
            for any indirect, incidental, special, consequential, or punitive
            damages, or any loss of profits or revenues, whether incurred
            directly or indirectly, or any loss of data, use, goodwill, or other
            intangible losses, resulting from (i) your use of or inability to
            use the Signature Module; (ii) any unauthorized access to or use of
            our servers and/or any personal information stored therein; (iii)
            any bugs, viruses, trojan horses, or the like that may be
            transmitted to or through our service by any third party.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-0">9) Changes to Terms</h3>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            We may revise these Terms from time to time. The most current
            version will always be posted on our website.
          </li>
          <li>
            By continuing to access or use the Signature Module after those
            revisions become effective, you agree to be bound by the revised
            Terms.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-0">10) Governing Law</h3>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            These Terms are governed by and construed in accordance with the
            laws of NSW,Australia without regard to its conflict of laws
            principles.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="text-dark mb-0">11) Contact Information</h3>
        <ul className="ms-5 mb-3 mt-3">
          <li>
            For any questions or concerns about these Terms, please contact us
            at <a href="mailto:hello@docutick.com">hello@docutick.com</a>
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          By using the Docutick Signature Module, you agree to these Terms of
          Service. Thank you for choosing Docutick for your document signing
          needs.
        </p>
        <br />
        <br />
      </div>
    </div>
  );
}

export default TermsConditionsOnlyForSign;
