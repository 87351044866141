import React from "react";
import {useNavigate} from "react-router-dom";
import ProductFeatures from "../partials/ProductFeatures";
import Testimonial from "../partials/Testimonial";
import {Lang} from "../../../lang";

function Product() {
    const navigate = useNavigate();

    document.title = Lang.product_title;
    document.getElementsByTagName("META")[4].content = Lang.product_meta_desc;
    document.getElementsByTagName("META")[5].content = Lang.product_meta_keyword;

    const handleSignUp = (e) => {
        e.preventDefault();
        navigate('/sign-up');
    };

    return (
        <div className="p-0" style={{minHeight: 'calc(100vh - 401px)'}}>
            <div className="title_container bg-primary px-4 py-5">
                <h1 className="text-dark text-center mb-3">Product</h1>
                <p className="text-dark text-center">DocuTick – The Ultimate Platform for Document Collection, Information Management, and Digital Signatures</p>
            </div>

            <div className="custom_container product_page">
                <div className="row my-5">
                    <div className="col-md-6 order-2 order-md-1">
                        <h2 className="text-dark  mb-4">DocuTick – The Ultimate Platform for Document Collection, Information Management, and Digital Signatures</h2>
                        <h5 className="text-dark  mb-4">Streamline Your Workflow with DocuTick</h5>
                        <p>DocuTick is a powerful and intuitive platform that transforms the way businesses collect documents, gather information, and secure digital signatures. Designed to enhance efficiency, security, and compliance, DocuTick helps businesses of all sizes manage administrative tasks with ease. Whether you're in finance, legal, HR, or any other industry, DocuTick provides the tools necessary for seamless digital operations.</p>
                        
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h2 className="text-dark  mt-0 mt-lg-5 mb-4">Key Features of DocuTick</h2>

                        <h4 className="text-dark  mt-0 mt-lg-5 mb-4">1. Efficient Document Collection</h4>

                        <p className="mb-4">Easily create customized document requests and manage multiple submissions from clients, employees, or partners. Real-time tracking ensures timely responses, while automated workflows reduce manual follow-ups.</p>
                        


                            <ul className="ps-3 ms-3">
                            <li className="ps-4 mb-3">Customizable Document Requests</li>
                            <li className="ps-4 mb-3">Real-Time Tracking of Submissions</li>
                            <li className="ps-4 mb-3">Centralized Document Storage</li>
                            <li className="ps-4 mb-3">Multi-File Support for All Formats</li>
                        </ul>


                    </div>
                </div>
                <div className="row my-5">
                <div className="col-md-6">
                       

                        <h4 className="text-dark  mt-0 mt-lg-5 mb-4">2. Streamlined Information Gathering</h4>

                        <p className="mb-4">Our platform allows you to design and distribute custom forms to collect the specific information you need. Whether it's client details, project data, or compliance forms, DocuTick ensures accurate data collection with minimal effort.</p>
                        


                            <ul className="ps-3 ms-3">
                            <li className="ps-4 mb-3">Custom Form Builder</li>
                            <li className="ps-4 mb-3">Automated Information Collection</li>
                            <li className="ps-4 mb-3">Organized Data Storage</li>
                            <li className="ps-4 mb-3">Secure Submission Channels</li>
                        </ul>


                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>


                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        
                        <h4 className="text-dark  mt-0 mt-lg-5 mb-4">3. Secure Digital Signatures</h4>

                        <p className="mb-4">With legally binding digital signatures, DocuTick makes it easy to sign contracts, agreements, and other critical documents from anywhere. This feature speeds up approval processes, saves time, and keeps you compliant with global e-signature standards.</p>
                        


                            <ul className="ps-3 ms-3">
                            <li className="ps-4 mb-3">Legally Binding Signatures</li>
                            <li className="ps-4 mb-3">Remote Signing Capabilities</li>
                            <li className="ps-4 mb-3">Secure Signature Storage</li>
                            <li className="ps-4 mb-3">Compliant with Industry Standards (eIDAS, ESIGN)</li>
                        </ul>


                    </div>
                </div>
                <div className="row my-5">
                <div className="col-md-6">
                       

                        <h4 className="text-dark  mt-0 mt-lg-5 mb-4">4. Automated Reminders and Deadlines</h4>

                        <p className="mb-4">Never miss a deadline again with automated reminders and deadline notifications. DocuTick keeps your processes running smoothly, ensuring that all tasks are completed on time without the need for manual tracking.</p>
                        


                            <ul className="ps-3 ms-3">
                            <li className="ps-4 mb-3">Task Tracking & Notifications</li>
                            <li className="ps-4 mb-3">Automated Email Reminders</li>
                            <li className="ps-4 mb-3">Manage Deadlines Across Projects</li>
                            <li className="ps-4 mb-3">Customizable Reminders for Each User</li>
                        </ul>


                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>

                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        
                        <h4 className="text-dark  mt-0 mt-lg-5 mb-4">5. Seamless Integration with Cloud Platforms</h4>

                        <p className="mb-4">Easily integrate DocuTick with your favorite cloud storage services like Google Drive, OneDrive, Dropbox, and Box. This ensures all your documents are securely stored, easily accessible, and synced across your workflow.</p>
                        


                            <ul className="ps-3 ms-3">
                            <li className="ps-4 mb-3">Google Drive, Dropbox, OneDrive, Box Integration</li>
                            <li className="ps-4 mb-3">Automatic Document Syncing</li>
                            <li className="ps-4 mb-3">Secure Cloud Storage</li>
                            <li className="ps-4 mb-3">Organized Folder Structures</li>
                        </ul>


                    </div>
                </div>




            </div>

            <div className="custom_container-fluid py-5" style={{backgroundColor: '#f6f9fc'}}>
                <h2 className="text-dark text-center mb-4">How DocuTick Benefits Your Business</h2>
                <h4 className="text-dark text-center mb-4">Automate your document & information collection With
                    DocuTick.</h4>
                <p className=" text-center mt-3 mx-auto mb-4" style={{maxWidth: '750px', width: '100%'}}>You can
                    automate and streamline the entire workflow involved with your organization’s document collection
                    processes with DocuTick.</p>
                <div className="custom_container my-5">
                    <div className="row gy-5">
                        <div className="col-lg-4 col-md-6">
                            <div className="w-100 py-3 bg-white d-flex align-items-center justify-content-center"
                                 style={{borderRadius: '25px'}}>
                                <img src="/images/product-name.png" alt="..."/>
                            </div>
                            <div className="px-2">
                                <h4 className="text-dark text-center my-4">Boost Productivity</h4>
                                <p className="text-center">DocuTick helps you save time by automating manual tasks like document follow-ups, information collection, and digital approvals. Spend less time on administrative work and more time focusing on what matters.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="w-100 py-3 bg-white d-flex align-items-center justify-content-center"
                                 style={{borderRadius: '25px'}}>
                                <img src="/images/product-name.png" alt="..."/>
                            </div>
                            <div className="px-2">
                                <h4 className="text-dark text-center my-4">Improve Client and Employee Experience</h4>
                                <p className="text-center">Provide a streamlined and secure experience for both clients and employees. From hassle-free document submissions to easy form-filling and electronic signatures, DocuTick ensures smooth, professional interactions.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="w-100 py-3 bg-white d-flex align-items-center justify-content-center"
                                 style={{borderRadius: '25px'}}>
                                <img src="/images/product-name.png" alt="..."/>
                            </div>
                            <div className="px-2">
                                <h4 className="text-dark text-center my-4">Enhance Compliance and Security</h4>
                                <p className="text-center">With built-in compliance features like audit trails, encrypted storage, and two-factor authentication, DocuTick ensures your business stays compliant with legal and regulatory standards, safeguarding sensitive data.</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

            <ProductFeatures/>

            <Testimonial/>

            <div className="custom_container my-5">
                <div className="  px-4 py-5  border_radius flexWrap bg-primary text-center">
                    <h2 className="text-white me-4 text-center mb-3">Have any Query? Talk to our Support Team.</h2>
                    <p className="mb-3 text-white">Thousand+ Clients all over the world use DocuTick.</p>
                    <button onClick={handleSignUp} type="button" className="btn book-now bg-white text-primary">
                        Try For Free
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Product;