import React from "react";
import {Lang} from "../../../lang";

function UseCases() {

    document.title = Lang.use_cases_title;
    document.getElementsByTagName("META")[4].content = Lang.use_cases_meta_desc;
    document.getElementsByTagName("META")[5].content = Lang.use_cases_meta_keyword;

    return (
        <div className="p-0" style={{minHeight: 'calc(100vh - 406px)'}}>
            <div className="title_container bg-primary px-4 py-5">
                <h1 className="text-dark text-center mb-3">Use Cases</h1>
                <p className="text-dark text-center mb-4">DocuTick streamlines document collection, data gathering, and e-signature workflows across diverse industries, helping you work smarter and more efficiently.</p>
                
            </div>

            <div className="custom_container_portal use-cases">
                <div className="row my-5">
                    <div className="col-md-6 order-2 order-md-1">
                        <h3 className="text-dark  mb-4">Accounting & Bookkeeping</h3>
                        <p>DocuTick simplifies document and data collection for accounting and bookkeeping tasks, ensuring that financial records, tax documents, and statements are securely gathered and signed through digital signatures.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Automated reminders ensure timely collection of financial documents.</li>
                            <li className="ps-4 mb-3">Secure e-signatures for financial approvals.</li>
                            <li className="ps-4 mb-3">Centralized storage for easy access to invoices, tax forms, and ledgers.</li>
                            <li className="ps-4 mb-3">Real-time tracking of document submissions for compliance and auditing.</li>
                            <li className="ps-4 mb-3">Exceptional and professional client portal</li>
                            <li className="ps-4 mb-3">Integration with cloud platforms for secure and organized record-keeping.</li>
                        </ul>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-dark  mb-4">Taxation</h3>
                        <p>Managing tax documents and forms is easier with DocuTick, offering secure document and data collection, along with e-signatures for approvals and filings.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect tax documents</li>
                            <li className="ps-4 mb-3">E-signature feature for approval of tax filings.</li>
                            <li className="ps-4 mb-3">Streamlined data collection for tax returns and reports.</li>
                            <li className="ps-4 mb-3">Secure storage ensures compliance with tax regulations.</li>
                            <li className="ps-4 mb-3">Automated reminders for upcoming tax deadlines.</li>
                        </ul>
                    </div>
                </div>

                <div className="row my-5">
                    <div className="col-md-6 order-2 order-md-1">
                        <h3 className="text-dark  mb-4">Auditing</h3>
                        <p>Auditors can streamline their processes with DocuTick, using its document and data collection tools and secure e-signatures for approvals.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect audit documents securely in one centralized system.</li>
                            <li className="ps-4 mb-3">Ensure compliance with regulatory requirements using audit trails.</li>
                            <li className="ps-4 mb-3">E-signatures for audit confirmations and legal documents.</li>
                            <li className="ps-4 mb-3">Track real-time submissions of reports and financial statements.</li>
                            <li className="ps-4 mb-3">Integration with cloud platforms for secure document storage.</li>
                           
                        </ul>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-dark  mb-4">Mortgages</h3>
                        <p>DocuTick helps mortgage professionals collect documents and client data seamlessly, while ensuring e-signatures are secure for loan agreements and contracts.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Streamline collection of loan applications, income verification, and appraisals.</li>
                            <li className="ps-4 mb-3">E-signatures ensure secure and compliant mortgage agreements.</li>
                            <li className="ps-4 mb-3">Real-time tracking of document submissions for faster approvals.</li>
                            <li className="ps-4 mb-3">Centralized data collection for efficient underwriting and decision-making.</li>
                            <li className="ps-4 mb-3">Integration with cloud storage for easy retrieval of mortgage documents.</li>
                            
                        </ul>
                    </div>
                </div>

                <div className="row my-5">
                    <div className="col-md-6 order-2 order-md-1">
                        <h3 className="text-dark  mb-4">Lending & Brokerage</h3>
                        <p>DocuTick improves the efficiency of document and data collection for lenders and brokers while simplifying e-signatures for contracts and agreements.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect financial statements, credit reports, and legal documents in one place.</li>
                            <li className="ps-4 mb-3">E-signatures for secure loan agreements and brokerage contracts.</li>
                            <li className="ps-4 mb-3">Automated workflows reduce the time spent on document chasing.</li>
                            <li className="ps-4 mb-3">Centralized storage for compliance with lending and brokerage regulations.</li>
                            <li className="ps-4 mb-3">Cloud integration for secure access and storage of transaction documents.</li>
                           
                        </ul>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-dark  mb-4">Real Estate</h3>
                        <p>DocuTick supports real estate professionals by facilitating document and data collection for property transactions and ensuring secure e-signatures for contracts.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect property disclosures, contracts, and inspection reports quickly.</li>
                            <li className="ps-4 mb-3">E-signatures for real estate contracts ensure legal compliance.</li>
                            <li className="ps-4 mb-3">Centralized system for storing buyer and seller documents securely.</li>
                            <li className="ps-4 mb-3">Real-time tracking of document submissions for smoother closings.</li>
                            <li className="ps-4 mb-3">Cloud storage ensures easy access to property documents anytime.</li>
                            
                        </ul>
                    </div>
                </div>


                <div className="row my-5">
                    <div className="col-md-6 order-2 order-md-1">
                        <h3 className="text-dark  mb-4">Immigration</h3>
                        <p>DocuTick makes it easier for immigration professionals to gather required documents and client data while using e-signatures for legal paperwork.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect immigration forms, passports, and financial documents securely.</li>
                            <li className="ps-4 mb-3">E-signatures for visa applications and affidavits.</li>
                            <li className="ps-4 mb-3">Automated workflows streamline case management.</li>
                            <li className="ps-4 mb-3">Secure cloud storage for immigration documents and client records.</li>
                            <li className="ps-4 mb-3">Automated reminders ensure timely submissions for immigration processes.</li>
                           
                        </ul>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-dark  mb-4">Insurance</h3>
                        <p>Insurance agencies benefit from DocuTick’s ability to collect claims, policies, and data with the added security of e-signatures for approvals.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Securely collect claim forms, policy applications, and supporting documents.</li>
                            <li className="ps-4 mb-3">E-signatures ensure faster approvals for insurance contracts.</li>
                            <li className="ps-4 mb-3">Centralized document storage for compliance and auditing.</li>
                            <li className="ps-4 mb-3">Automated reminders for claim submissions and renewals.</li>
                            <li className="ps-4 mb-3">Real-time tracking of document collection and approval processes.</li>
                            
                        </ul>
                    </div>
                </div>




                <div className="row my-5">
                    <div className="col-md-6 order-2 order-md-1">
                        <h3 className="text-dark  mb-4">Legal Services</h3>
                        <p>DocuTick offers legal professionals a secure platform for document collection, data management, and e-signatures for contracts and legal agreements.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect legal documents like affidavits, contracts, and case files securely.</li>
                            <li className="ps-4 mb-3">E-signatures ensure binding agreements and legal compliance.</li>
                            <li className="ps-4 mb-3">Real-time tracking of submissions for faster case resolution.</li>
                            <li className="ps-4 mb-3">Secure storage ensures confidentiality and compliance with regulations.</li>
                            <li className="ps-4 mb-3">Automated workflows streamline client communication and document handling.</li>
                           
                        </ul>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-dark  mb-4">Financial Services</h3>
                        <p>DocuTick simplifies document and data collection for financial services, ensuring e-signatures are secure for contracts, agreements, and transactions.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect financial statements, tax returns, and investment records.</li>
                            <li className="ps-4 mb-3">Secure e-signatures for contracts, transactions, and agreements.</li>
                            <li className="ps-4 mb-3">Real-time tracking of document submissions and approvals.</li>
                            <li className="ps-4 mb-3">Automated workflows improve efficiency in financial decision-making.</li>
                            <li className="ps-4 mb-3">Secure cloud storage ensures compliance with financial regulations.</li>
                            
                        </ul>
                    </div>
                </div>



                <div className="row my-5">
                    <div className="col-md-6 order-2 order-md-1">
                        <h3 className="text-dark  mb-4">Education</h3>
                        <p>DocuTick supports educational institutions by streamlining document and data collection for admissions, student records, and faculty contracts with secure e-signatures.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect admission forms, academic records, and enrollment data.</li>
                            <li className="ps-4 mb-3">Secure e-signatures for student and faculty agreements.</li>
                            <li className="ps-4 mb-3">Real-time tracking of document submissions for easy monitoring.</li>
                            <li className="ps-4 mb-3">Automated reminders ensure timely collection of student and staff documents.</li>
                            <li className="ps-4 mb-3">Secure cloud storage for student records and academic documents.</li>
                           
                        </ul>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-dark  mb-4">Human Resources</h3>
                        <p>HR departments can use DocuTick to simplify document and data collection for onboarding, employee records, and e-signatures for contracts.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect employee records, tax forms, and compliance documents securely.</li>
                            <li className="ps-4 mb-3">E-signatures for employment contracts and policy acknowledgments.</li>
                            <li className="ps-4 mb-3">Real-time tracking of document submissions during onboarding.</li>
                            <li className="ps-4 mb-3">Automated workflows ensure compliance with HR regulations.</li>
                            <li className="ps-4 mb-3">Centralized cloud storage for easy access to employee documents.</li>
                            
                        </ul>
                    </div>
                </div>




                <div className="row my-5">
                    <div className="col-md-6 order-2 order-md-1">
                        <h3 className="text-dark  mb-4">Digital Agencies</h3>
                        <p>DocuTick enhances digital agency workflows by enabling easy document and data collection, with secure e-signatures for client contracts and project approvals.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect client briefs, project documents, and design files efficiently.</li>
                            <li className="ps-4 mb-3">E-signatures for client contracts ensure faster approvals.</li>
                            <li className="ps-4 mb-3">Real-time tracking of document submissions for project timelines.</li>
                            <li className="ps-4 mb-3">Automated workflows streamline client communication and feedback collection.</li>
                            <li className="ps-4 mb-3">Cloud integration for secure storage of project files and contracts.</li>
                           
                        </ul>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="image_wrap">
                            <img src="/images/usecases.png" alt="..."/>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/usecases-1.png" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-dark  mb-4">Administration</h3>
                        <p>DocuTick supports administrative departments in efficiently managing document and data collection, with secure e-signatures for approvals and records.</p>
                        <h5 className="text-dark my-4">Benefits</h5>
                        <ul>
                            <li className="ps-4 mb-3">Collect internal reports, policy documents, and compliance records.</li>
                            <li className="ps-4 mb-3">E-signatures for internal approvals and external contracts.</li>
                            <li className="ps-4 mb-3">Real-time tracking of document submissions and approvals.</li>
                            <li className="ps-4 mb-3">Centralized storage for easy retrieval and management of documents.</li>
                            <li className="ps-4 mb-3">Automated workflows improve organizational efficiency and compliance.</li>
                            
                        </ul>
                    </div>
                </div>





            </div>
        </div>
    );
}

export default UseCases;