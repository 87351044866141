import React from "react";
import { Lang } from "../../../lang";

function PrivacyPolicy() {

    document.title = Lang.privacy_policy_title;
    document.getElementsByTagName("META")[4].content = Lang.privacy_policy_meta_desc;
    document.getElementsByTagName("META")[5].content = Lang.privacy_policy_meta_keyword;

    return (
        <div className=" p-0" style={{ minHeight: 'calc(100vh - 406px)' }}>
            <div className="title_container px-4 py-5">
                <h1 className="text-dark text-center mb-3">Privacy Policy</h1>
                <p className="text-dark text-center">We are extremely concerned to protect your privacy and
                    confidentiality. Our policy includes what we can record about you & your provided information on our
                    website.</p>
                <br />
            </div>

            <div className=" custom_container_portal py-5 px-3">
                <h3 className="text-dark mb-4">Privacy Policy of [www.docutik.com].</h3>
                <p><strong>Effective Date: 01/08/2023</strong></p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">1) Introduction</h3>
                <p>Welcome to Docutick ("we," "us," "our"). We are committed to safeguarding the privacy of our users and protecting personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our services.</p>
                <p>Please find complete details of Docutick</p>
                <p>&nbsp;</p>
                <p>Full name of legal entity: &nbsp;</p>
                <p>Trading As: <strong>Docutick Pty Ltd</strong></p>
                <p>Email address:&nbsp;<strong><a href="mailto:hello@docutick.com">hello@docutick.com</a></strong></p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">2) Information We Collect</h3>
                <p><strong>What information do we collect ?</strong></p>
                <br />
                <p><strong>2.1 User-Provided Information:</strong></p>
                <br />
                <p>When using Docutick, you or your clients may provide us with the following personal information:</p>
                <ul className="ms-3 mb-3 mt-3">
                    <li>Identity details: Includes first name, last name,company name. Users are requested to submit only mandatory personal information</li>
                    <li>Communication Details: Correspondence via our platform, email, or phone.</li>
                    <li>Document Data: Information and documents uploaded to our platform, including signatures. Users are highly encouraged to o delete information after usage.</li>
                    <li> Payment/Transaction details:&nbsp; may include&nbsp; card details , transactional details etc.</li>
                </ul>
                <p>&nbsp;</p>
                <p><strong>2.2 Automatically Collected Information:</strong></p>
                <br />
                <p>We may automatically collect certain information, including:</p>
                <ul className="ms-3 mb-3 mt-3">
                    <li><strong>Technical Data:</strong> - may include Internet protocol (IP) address, browser type, device type, operating system, and time zone.</li>
                    <li><strong>Usage Data:</strong> may include details of your interactions with our platform, such as pages visited and actions taken.</li>
                </ul>
                <p><strong>Why we collect your information</strong></p>
                <p>At Docutick, collecting user information, including personal, profile, and payment information, is essential for providing a secure, efficient, and personalized user experience. We don’t collect any sensitive personal &nbsp;information. Sensitive personal information includes information or an opinion about an individual’s racial or ethnic origin ,political opinions or associations,religious or philosophical beliefs ,trade union membership or associations,sexual orientation or practices,criminal record,health or genetic information,some aspects of&nbsp;biometric information.
                </p>
                <p>Here are the key reasons why Docutick collect information</p>
                <ol className="ms-5 mb-3 mt-3" style={{ lineHeight: '1.5em' }}>
                    <li>Service Provision and Personalization:<br /> Collecting personal information such as names, email addresses, and contact details allows Docutick to deliver tailored services and communications. It helps in personalizing the user experience, addressing users by name, and ensuring that the content and services provided are relevant and useful.</li>
                    <br />
                    <li>Account Management and Security:<br /> Profile information, including account settings and preferences, enables users to manage their accounts effectively. This data is crucial for maintaining the security of user accounts, verifying user identities, and preventing unauthorized access.</li>
                    <br />
                    <li>Payment Processing and Transaction Security:<br /> Payment information is necessary for processing transactions securely. Docutick uses this data to manage billing, handle payments, and process refunds. Secure handling of payment details ensures that users can confidently conduct transactions without the risk of data breaches. We do not Store financial information such as bank card details. Online payments for our services are processed remotely through trusted third-party payment gateways like Stripe and PayPal. You should check their policies for how they handle your information.</li>
                    <br />
                    <li>Improving Services and User Experience:<br /> By analyzing usage data and user feedback, Docutick can continually improve its platform, fix issues, and enhance features. Understanding how users interact with the platform helps in optimizing the service and delivering a better overall experience.</li>
                    <br />
                    <li>Communication and Support:<br /> Contact information is essential for providing customer support, sending important updates, and communicating about account-related matters. It allows Docutick to assist users promptly and efficiently.</li>
                    <br />
                </ol>
                <p>In summary, the collection of user information at Docutick is fundamental to ensuring the platform's functionality, security, and compliance, as well as enhancing the overall user experience.</p>
                <p>&nbsp;</p>
                <p><strong>How we collect information:</strong></p>
                <br />
                <p>Docutick collects information from users through various methods to ensure a seamless and secure experience on the platform.</p>
                <br />
                <p><strong><u>user-provided information</u></strong> is collected when users create accounts, fill out forms, or interact with the platform. This includes personal details such as names, email addresses, and contact information, which are necessary for account setup and communication. Users may also provide documents and data, such as information uploads, digital signatures, and payment details, during the service process.</p>
                <br />
                <p><strong><u>Docutick automatically collects information</u></strong> through the use of cookies, web beacons, and other tracking technologies. This includes device information (e.g., IP addresses, browser types, and operating systems) and usage data, such as the pages users visit and their interactions with the platform. This data helps in understanding user behavior, optimizing the user experience, and enhancing the security of the platform.</p>
                <br />
                <p><strong><u>Information may be collected from third-party sources</u> </strong>when users interact with integrated services or platforms, such as payment processors or authentication services. These integrations help streamline user processes and ensure secure transactions. Through these combined methods, Docutick gathers essential data to provide, personalize, and secure its services for all users.</p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>How We Use Your Information</strong></p>
                <br />
                <p>Docutick utilizes the information collected from users for a variety of essential purposes, ensuring that the platform operates smoothly, securely, and in compliance with legal standards.</p>
                <br />
                <p>We will use your personal data solely for the purposes for which it was initially collected, unless we determine that there is a need to use it for another reason that aligns with the original intent. The use of document including signatures &nbsp;collected through Docutick Services is strictly confined to the purposes for which you have engaged our platform. &nbsp;When you engage with Docutick for storing documents, information and signatures, we act as data storing platform and you are responsible for the contents uploaded or shared with receipients.We ensue security of the information and are not responsible for the contents of the documents uploaded in the platform.&nbsp; Once &nbsp;&nbsp;documents/information is &nbsp;received from recipients , users are encouraged to backup &nbsp;required information &nbsp;and delete from Docutick platform.</p>
                <p>&nbsp;</p>
                <p>Docutick doesn’t maintain direct relationship with the recipients of the envelopes. For any queries related to envelope, you should direct your requests to the sender of the envelopes. They are responsible for managing these envelopes.</p>
                <br />
                <p>Here’s an in-depth look at how we use this information:</p>
                <p>&nbsp;</p>
                <p><strong><u>Service Delivery</u></strong></p>
                <br />
                <p>Providing Services: The primary use of collected information is to deliver the core services offered by Docutick. By knowing user preferences and requirements, we can tailor our services to meet specific needs effectively.</p>
                <p>&nbsp;</p>
                <p>Maintaining and Improving Services: By analyzing usage data and user feedback, Docutick identifies areas that need enhancement, develops new features, and optimizes existing functionalities to provide a better user experience. This ongoing refinement ensures that the platform remains reliable, user-friendly, and up-to-date with technological advancements.</p>
                <p>&nbsp;</p>
                <p><strong><u>Communication</u></strong></p>
                <br />
                <p>Account Management: Docutick uses this data to send essential communications related to account activities, such as confirmations, alerts, and notifications about updates or changes to the services.</p>
                <br />
                <p><strong><u>Customer Support:</u></strong> <br /><br />Effective communication channels are necessary for providing timely and helpful customer support. Whether users have inquiries, face issues, or need guidance, having their contact information allows us to offer prompt and personalized assistance, ensuring that users can resolve their concerns quickly.</p>
                <p>&nbsp;</p>
                <p><strong><u>Security</u></strong></p>
                <br />
                <p>Protecting User Data: Security is a top priority at Docutick. We use collected information to implement robust security measures that protect user data from unauthorized access, breaches, and other threats. This includes encryption, secure authentication methods, and regular security audits.</p>
                <p>&nbsp;</p>
                <p><strong><u>Monitoring and Preventing Fraud:</u></strong> <br /><br />By analyzing usage patterns and device information, we can detect suspicious activities that might indicate fraudulent behavior. This proactive monitoring helps prevent fraud and protects both the platform and its users from potential security risks.</p>
                <p>&nbsp;</p>
                <p><strong><u>Legal Compliance</u></strong></p>
                <br />
                <p>Adhering to Regulations: Docutick may disclose your personal information if required to do so by law or in response to legal processes. This includes situations where we must comply with legal obligations, such as tax reporting requirements, or respond to lawful requests from public authorities, such as law enforcement agencies. Such disclosures are made to uphold the law, protect the rights and safety of our users and the public, and prevent fraud or abuse of our platform.</p>
                <p>&nbsp;</p>
                <p><strong><u>Protecting Our Rights:</u></strong><br /><br /> In situations where it is necessary to enforce our terms of service or defend against legal claims, having accurate and detailed user information allows us to take appropriate actions. This includes investigating and addressing any violations or disputes that may arise.</p>
                <p>&nbsp;</p>
                <p>By utilizing user information for these purposes, Docutick ensures that the platform operates efficiently, provides a high level of service, maintains robust security, and adheres to legal standards, ultimately fostering trust and satisfaction among its users.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">3) Sharing Your Information</h3>
                <p>Docutick is committed to protecting your privacy and ensuring that your personal information is handled with care. While we do not sell, trade, or rent your personal information, there are specific circumstances under which we may share your data:</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">4) Service Providers</h3>
                <p>To deliver our services effectively, Docutick partners with various third-party vendors. These service providers may include payment processors, data storage providers, and customer support platforms. These third parties are only given access to the personal information necessary to perform their specific functions and are contractually obligated to maintain the confidentiality and security of your data.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">5) Legal Requirements</h3>
                <p>Docutick may disclose your personal information if required to do so by law or in response to legal processes. This includes situations where we must comply with legal obligations, such as tax reporting requirements, or respond to lawful requests from public authorities, such as law enforcement agencies. Such disclosures are made to uphold the law, protect the rights and safety of our users and the public, and prevent fraud or abuse of our platform.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">6) Business Transfers</h3>
                <p>In the event of a significant corporate transaction, such as a merger, acquisition, or sale of all or a portion of our assets, Docutick may transfer your personal information to the new entity. This transfer is necessary to ensure the continuity of our services and operations. In such cases, the new entity will be bound by the terms of this privacy policy or a substantially similar policy that maintains the same level of protection for your personal information.</p>
                <p>&nbsp;</p>
                <p>These measures are in place to ensure that your personal data is used responsibly and only when necessary for the functioning and legal compliance of Docutick's services. We prioritize your privacy and take all necessary steps to protect your information in these scenarios.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">7) Data Security</h3>
                <p>Docutick employs industry-standard security measures to safeguard your personal information, ensuring it is protected both at rest and in transit.</p>
                <p>&nbsp;</p>
                <p><strong><u>Data Security in Rest</u></strong></p>
                <br />
                <p>When data is stored (at rest), Docutick uses advanced encryption technologies to secure it. This means that all documents, personal information, and sensitive data are encrypted using strong cryptographic algorithms, such as AES-256, which is widely recognized for its robust security.</p>
                <p>&nbsp;</p>
                <p>Additionally, Docutick implements access controls and authentication mechanisms to restrict access to sensitive data. Only authorized personnel with a legitimate need to access the data can do so, and even then, access is closely monitored and logged to detect any unauthorized activities.</p>
                <p>&nbsp;</p>
                <p><strong><u>Data Security in Transit</u></strong></p>
                <br />
                <p>Data security in transit refers to the protection of data as it is transmitted over networks, such as the internet. Docutick ensures that all data transfers between users and the platform are encrypted using Transport Layer Security (TLS) protocols. TLS encryption protects the data by establishing a secure and encrypted connection between the user's device and Docutick's servers, preventing unauthorized interception or tampering during transmission.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">8) Overall Security</h3>
                <p>While Docutick strives to use commercially acceptable means to protect personal information, it acknowledges that no method of data transmission or storage is completely secure. This understanding drives the continuous improvement of security measures and practices to safeguard user data as effectively as possible. The commitment to data security is an ongoing process, involving regular audits, updates, and adherence to the latest security standards and protocols.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">9) Your Rights</h3>
                <p>You are entitled to the following rights concerning your personal information:</p>
                <ul className="ms-3 mb-3 mt-3">
                    <li><strong>Access and Rectification:</strong> You have the right to access your personal information stored with us and make any necessary updates or corrections through your account settings. This ensures that your data is accurate and up-to-date.</li>
                    <br />
                    <li><strong>Data Portability:</strong> You may request a copy of your personal data in a structured, commonly used, and machine-readable format. This allows you to transfer your data to another service provider easily.</li>
                    <br />
                    <li><strong>Erasure ("Right to be Forgotten"):</strong> You can request the deletion of your personal information from our systems, subject to any legal obligations that may require us to retain certain data.</li>
                    <br />
                    <li><strong>Delete your data :</strong> You are encouraged to delete documents/information/signature after collecting from recipients</li>
                </ul>
                <p>To exercise any of the aforementioned rights, please contact us at <a href="mailto:hello@docutick.com">hello@docutick.com</a>. We are committed to handling your requests promptly and transparently. While we do not charge a fee for accessing your personal data or exercising these rights, we reserve the right to impose a reasonable fee if your request is clearly unfounded, repetitive, or excessive. In certain circumstances, we may decline to comply with your request, in which case we will provide a clear explanation.</p>
                <br />
                <p>By using the Docutick Services, you acknowledge and agree that: (i) your information will be processed in accordance with the terms outlined in this Privacy Policy; and (ii) you consent to your information/documents being transferred to our infrastructures in Australia &nbsp;&nbsp;or other locations, including those of third parties as detailed in this Privacy Policy.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">10) Cookies and Tracking Technologies</h3>
                <p>We use cookies and similar technologies to enhance your experience on our platform. These technologies help us track your interactions with our site, allowing us to offer personalized features such as tailored content and customized settings. By storing small pieces of data on your device, cookies enable us to remember your preferences and login details, making your subsequent visits more seamless. Additionally, cookies can be used to deliver targeted advertisements and measure the effectiveness of our marketing campaigns, ensuring you receive relevant information.</p>
                <br />
                <p>You can manage your cookie preferences through your browser settings, where you can choose which cookies to accept or reject. Disabling cookies may affect the functionality of our platform, as some features, like auto-login and personalized settings, rely on these technologies to function properly. Moreover, if you prefer, you may choose to clear your browsing history or work in incognito mode to prevent cookies from being stored on your device. However, using these options may limit your experience, as you might need to manually enter login information and other settings each time you visit the site. Despite these drawbacks, we provide these options to give you control over your privacy and browsing experience.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">11) International Data Transfers</h3>
                <p>Your data may be transferred and processed outside your country of residence. We ensure appropriate safeguards are in place to protect your data during such transfers.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">12) Third-Party Links</h3>
                <p>Our platform may include hyperlinks to external websites or services operated by third parties. It is important to note that we do not control these third-party entities and, therefore, are not accountable for their privacy practices or the content provided on their sites. We strongly advise you to review the privacy policies of any third-party websites or services you visit, as their practices regarding data collection, usage, and protection may differ significantly from our own.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">13) Docutick Payment Terms</h3>
                <p>&nbsp;These Payment Terms ("Terms") govern the payment policies and procedures for the use of Docutick services ("Services"). By using our Services, you ("Customer") agree to comply with and be bound by these Terms. If you do not agree to these Terms, you must not use our Services.</p>
                <p>&nbsp;</p>
                <ol className="ms-4 mb-3 mt-3">
                    <li><strong> Payment Obligations</strong></li>
                    <br />
                    <p><strong>&nbsp;1.1 Subscription Fees</strong></p>
                    <p>- Customers are required to pay subscription fees for the use of Docutick Services as specified in their chosen pricing plan. Subscription fees are billed in advance on a monthly or annual basis, depending on the plan selected.</p>
                    <p>&nbsp;</p>
                    <p><strong>&nbsp;1.2 Additional Fees</strong></p>
                    <p>- Additional fees may apply for extra services, such as additional document storage, SMS credits, or advanced features not included in the base subscription plan. These fees will be clearly outlined and agreed upon at the time of purchase.</p>
                    <p>&nbsp;</p>
                    <li><strong> Billing and Payment</strong></li>
                    <p>&nbsp;</p>
                    <p>&nbsp;<strong>2.1 Payment Methods</strong></p>
                    <p>- Docutick accepts various payment methods, including credit cards, debit cards, and other forms of electronic payment as specified on our website. By providing payment information, you represent and warrant that you are authorized to use the designated payment method and authorize Docutick to charge your payment method for the total amount of your subscription and any additional fees.</p>
                    <p>&nbsp;</p>
                    <p><strong>&nbsp;2.2 Billing Cycle</strong></p>
                    <p>- Subscription fees are billed on a recurring basis according to the billing cycle associated with your chosen plan (monthly or annually). The billing cycle begins on the date you subscribe to the Service and renews on the same date of each subsequent period.</p>
                    <p>&nbsp;</p>
                    <p><strong>&nbsp;2.3 Payment Terms</strong></p>
                    <p>- Payments are due and payable upon invoicing. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not update your payment method or cancel your account, we may suspend your access to the Service until we have successfully received payment.</p>
                    <p>&nbsp;</p>
                    <li><strong> Renewal and Cancellation</strong></li>
                    <p>&nbsp;</p>
                    <p><strong>&nbsp;3.1 Automatic Renewal</strong></p>
                    <p>- Your subscription will automatically renew at the end of each billing cycle unless you cancel/change the Service. The renewal will be for the same duration as the original subscription period unless you upgrade/downgrade the duration of the plan.</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;<strong>3.2 Cancellation Policy</strong></p>
                    <p>- You may cancel your subscription at any time by accessing your account settings or contacting our customer support. Cancellations will take effect at the end of the current billing cycle, and you will not be charged for the subsequent period.</p>
                    <p>&nbsp;</p>
                    <p><strong>&nbsp;3.3 Refund Policy</strong></p>
                    <p>- Payments are non-refundable except as required by law. If you cancel your subscription, you will retain access to the Service until the end of the current billing cycle, but no refunds or credits will be provided for partial use or unused services.</p>
                    <p>&nbsp;</p>
                    <li><strong> Changes to Pricing and Payment Terms</strong></li>
                    <p>&nbsp;</p>
                    <p>- Docutick reserves the right to modify its pricing and payment terms at any time. Any changes will be communicated to you in advance, and your continued use of the Service after the effective date of such changes constitutes your acceptance of the new terms.</p>
                    <p>&nbsp;</p>
                    <li><strong> Taxes</strong></li>
                    <p>&nbsp;</p>
                    <p>- All fees are exclusive of any taxes, levies, or duties imposed by taxing authorities. You are responsible for paying any such taxes associated with your purchase of Docutick services.</p>
                    <p>&nbsp;</p>
                    <li><strong> Disputes and Chargebacks</strong></li>
                    <p>&nbsp;</p>
                    <p>- If you believe there has been an error in billing, you must contact us within 30 days of the invoice date. We will review your claim and, if necessary, make corrections. Unauthorized chargebacks or payment disputes initiated by you may result in suspension or termination of your account.</p>
                    <p>&nbsp;</p>
                    <li><strong> Contact Information</strong></li>
                    <p>&nbsp;</p>
                    <p>- For any questions or concerns regarding these Payment Terms, please contact us at &nbsp;: <a href="mailto:hello@docutick.com">hello@docutick.com&nbsp;</a></p>
                    <a href="mailto:hello@docutick.com">
                        <p>&nbsp;</p>
                    </a>
                </ol>

                <h3 className="text-dark mb-4">14) Changes to This Privacy Policy</h3>
                <p>We reserve the right to amend this Privacy Policy periodically. Should there be any modifications, we will inform you by publishing the updated Privacy Policy on our website, accompanied by the revised effective date. By continuing to use our services following the implementation of these changes, you acknowledge and accept the new terms and conditions as outlined in the updated Privacy Policy.</p>
                <p>&nbsp;</p>
                <h3 className="text-dark mb-4">15) Contact Us</h3>
                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                <p>&nbsp;</p>
                <p>Docutick&nbsp;</p>

                <p><a href="mailto:hello@docutick.com">Email: </a><a href="mailto:hello@docutick.com">hello@docutick.com</a></p>
                <p>&nbsp;</p>
                <p>This privacy policy is designed to provide clear and comprehensive information about Docutick's data practices. It should be customized with specific details and regularly reviewed to ensure compliance with applicable laws and regulations.</p>
            </div>



            {/* 
            <div className=" custom_container_portal py-5 px-3">
                <h3 className="text-dark mb-4">Privacy Policy for www.DocuTick.com</h3>
                <p>Effective Date: 01/05/2023</p>
                <br /></br>
                <h3 className="text-dark mb-4">1. Introduction</h3>
                <p className="mb-4">Welcome to www.DocuTick.com ("we," "our," or "us"). We are committed to protecting the privacy of our users and their personal information. This Privacy Policy explains how we collect, use, disclose, and protect the information collected through our document collection software.</p>

                <h3 className="text-dark mb-4">2. Information We Collect</h3>
                <p className="mb-4">2.1 <b className="text-dark">User-Provided Information:</b> We may collect information that you or your clients provide directly when using our software. This may include:</p>
                <ul className="pl-0 mb-5">
                    <li className="mb-3 ps-2">Personal information such as your name, email address, and contact information.
                    </li>
                    <li className="mb-3 ps-2">Documents and data you or your clients upload or input into the software.
                    </li>
                </ul>
                <p className="mb-4">2.2 <b className="text-dark">Automatically Collected Information:</b> We may collect certain information automatically when you use our software, including:</p>
                <ul className="pl-0 mb-5">
                    <li className="mb-3 ps-2">Device information (e.g., IP address, device type, operating system).
                    </li>
                    <li className="mb-3 ps-2">Usage information (e.g., interactions with the software, pages visited, actions taken).
                    </li>
                </ul>

                <h3 className="text-dark mb-4">3. How We Use Your Information</h3>
                <p className="mb-4">We use the information collected for the following purposes:</p>
                <ul className="pl-0 mb-5">
                    <li className="mb-3 ps-2">To provide and improve our software and services.
                    </li>
                    <li className="mb-3 ps-2">To communicate with you regarding your account and software updates.
                    </li>
                    <li className="mb-3 ps-2">To respond to your inquiries and provide customer support.
                    </li>
                    <li className="mb-3 ps-2">To ensure the security of our software and users' data.
                    </li>
                    <li className="mb-3 ps-2">To comply with legal obligations
                    </li>
                </ul>


                <h3 className="text-dark mb-4">4. Sharing Your Information</h3>
                <p className="mb-4">We do not sell, trade, or rent your personal information to third parties. However, we may share your information with:</p>
                <ul className="pl-0 mb-5">
                    <li className="mb-3 ps-2">Service providers and partners who assist us in delivering our software and services.
                    </li>
                    <li className="mb-3 ps-2">Law enforcement or government authorities when required by applicable law.
                    </li>
                    <li className="mb-3 ps-2">In the event of a business merger, acquisition, or sale, your information may be transferred to a successor entity.
                    </li>
                </ul>

                <h3 className="text-dark mb-4">5. Data Security</h3>
                <p className="mb-4">We implement reasonable security measures to protect your data. However, no method of data transmission or storage is completely secure. We cannot guarantee the security of your information or your client’s documents and information.</p>
                
                <h3 className="text-dark mb-4">6. Your Choices</h3>
                <p className="mb-4">You have the following choices regarding your information:</p>
                <ul className="pl-0 mb-5">
                    <li className="mb-3 ps-2">You can review and update your personal information by logging into your account.
                    </li>
                    <li className="mb-3 ps-2">You may opt-out of receiving marketing communications from us
                    </li>
                    <li className="mb-3 ps-2">You can request the deletion of your account and associated data, subject to legal requirements.
                    </li>
                </ul>

                <h3 className="text-dark mb-4">7. Third-Party Links and Services</h3>
                <p className="mb-4">Our software may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties. Please review their privacy policies before using their services.</p>

                <h3 className="text-dark mb-4">8. Changes to this Privacy Policy</h3>
                <p className="mb-4">We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised effective date.</p>
                
                <h3 className="text-dark mb-4">9. Contact Us</h3>
                <p className="mb-4">If you have questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:hello@docutick.com">hello@docutick.com</a>.</p>

            </div> */}
        </div>
    );
}

export default PrivacyPolicy;