import React from "react";
import {Lang} from "../../../lang";

function Faq() {

    document.title = Lang.faq_title;
    document.getElementsByTagName("META")[4].content = Lang.faq_meta_desc;
    document.getElementsByTagName("META")[5].content = Lang.faq_meta_keyword;

    return (
        <div className="p-0" style={{minHeight: 'calc(100vh - 401px)'}}>
            <div className="title_container px-4 py-5">
                <h1 className="text-center text-dark mb-3">FAQ</h1>
                <p className="text-center text-dark">We have tried to answered the frequently Asked Questions.
                    <span className="d-block">if you have any specific Questions, please contact us.</span></p>
            </div>
            <section className="setting_tab">
                <div className="custom_container px-3 py-5 faq_nav">
                    <div className="row">
                        <div className="col-lg-2 col-md-4">
                            <h3 className="text-dark mb-4 ps-2">Topic</h3>
                            <div className="setting_sidebar mb-4">
                                <div className="nav nav-tabs " id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="AccountDetails" data-bs-toggle="tab"
                                            data-bs-target="#Account-detail" type="button" role="tab"
                                            aria-controls="Account-detail" aria-selected="true">General
                                    </button>
                                    <button className="nav-link" id="user-tab" data-bs-toggle="tab"
                                            data-bs-target="#user" type="button" role="tab" aria-controls="user"
                                            aria-selected="false">Subscription
                                    </button>
                                    <button className="nav-link " id="preferences-tab" data-bs-toggle="tab"
                                            data-bs-target="#preferences" type="button" role="tab"
                                            aria-controls="preferences" aria-selected="false">Product & Pricing
                                    </button>
                                    <button className="nav-link " id="integration-tab" data-bs-toggle="tab"
                                            data-bs-target="#integration" type="button" role="tab"
                                            aria-controls="integration" aria-selected="false">Security
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-8">
                            <h3 className="text-dark mb-4 ps-4">Question & Answer</h3>
                            <div className="tab-content  px-3" id="nav-tabContent">
                                <div className="tab-pane active show" id="Account-detail" role="tabpanel"
                                     aria-labelledby="AccountDetails">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                        aria-expanded="true" aria-controls="collapseOne">
                                                    What is an Envelope in DocuTick? 
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse"
                                                 aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                An Envelope in DocuTick is a set of document or information requests sent to one or multiple recipients. You can use an Envelope to request documents, information, or e-signatures from others in one organized batch.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                        aria-expanded="false" aria-controls="collapseTwo">
                                                    What are Recipients in DocuTick? 
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse"
                                                 aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Recipients are individuals or clients from whom you are requesting documents, information, or signatures via an Envelope.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                        aria-expanded="false" aria-controls="collapseThree">
                                                    What are Templates in DocuTick? 
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse"
                                                 aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Templates in DocuTick allow you to reuse pre-configured document requests for future use. This is useful for processes you run regularly, such as requesting similar documents from multiple clients.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                        aria-expanded="false" aria-controls="collapseFour">
                                                    What is the Number of Fulfilled Envelopes, and how does DocuTick charge? 
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse"
                                                 aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                A Fulfilled Envelope means when at least one document is uploaded by a recipient in response to your request. Even if you request multiple documents, only one credit is charged once a recipient uploads at least one document.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane  bg_transparent" id="user" role="tabpanel"
                                     aria-labelledby="user-tab">
                                    <div className="accordion" id="accordionExample2">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne1">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseOne1"
                                                        aria-expanded="true" aria-controls="collapseOne1">
                                                    How do I sign up for DocuTick? 
                                                </button>
                                            </h2>
                                            <div id="collapseOne1" className="accordion-collapse collapse"
                                                 aria-labelledby="headingOne1" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                You can sign up by visiting the DocuTick website and clicking on the “Get Started” or “Free Register” button. Simply follow the steps to create your account using your email. No credit card is needed for setup, and you will receive 3 free envelopes per month with the free plan.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo2">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo2"
                                                        aria-expanded="false" aria-controls="collapseTwo2">
                                                    How many Envelopes do I get in the Free Subscription? 
                                                </button>
                                            </h2>
                                            <div id="collapseTwo2" className="accordion-collapse collapse"
                                                 aria-labelledby="headingTwo2" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                With the free Subscription, you receive 3 free envelopes per month.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree3">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseThree3"
                                                        aria-expanded="false" aria-controls="collapseThree3">
                                                    What happens when I run out of credits?
                                                </button>
                                            </h2>
                                            <div id="collapseThree3" className="accordion-collapse collapse"
                                                 aria-labelledby="headingThree3" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                After you finish your credit on DocuTick, you won’t be able to send any more envelopes or request documents, information, or e-signatures until you upgrade your plan. However, you will still have access to your previously collected documents, and can manage them within the platform.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour4">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseFour4"
                                                        aria-expanded="false" aria-controls="collapseFour4">
                                                    Is it compulsory to verify my email for registration? 
                                                </button>
                                            </h2>
                                            <div id="collapseFour4" className="accordion-collapse collapse"
                                                 aria-labelledby="headingFour4" data-bs-parent="#accordionExample2">
                                                <div className="accordion-body">
                                                Yes, email verification is required to ensure the security and authenticity of your DocuTick account.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane  bg_transparent" id="preferences" role="tabpanel"
                                     aria-labelledby="preferences-tab">
                                    <div className="accordion" id="accordionExample3">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne11">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseOne11"
                                                        aria-expanded="true" aria-controls="collapseOne11">
                                                    Can I request documents from multiple people at the same time? 
                                                </button>
                                            </h2>
                                            <div id="collapseOne11" className="accordion-collapse collapse"
                                                 aria-labelledby="headingOne11" data-bs-parent="#accordionExample3">
                                                <div className="accordion-body">
                                                Yes, you can request documents from more than one person simultaneously by adding multiple recipients in one Envelope.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo22">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo22"
                                                        aria-expanded="false" aria-controls="collapseTwo22">
                                                    How many Documents and Recipients can I add in one Envelope? 
                                                </button>
                                            </h2>
                                            <div id="collapseTwo22" className="accordion-collapse collapse"
                                                 aria-labelledby="headingTwo22" data-bs-parent="#accordionExample3">
                                                <div className="accordion-body">
                                                <p>The limits vary by plan:</p>
<ul><li>Free Plan: Up to 20 documents and 5 recipients.</li>
<li>Pro Plan: Up to 30 documents and 15 recipients.</li>
<li>Team Plan: Up to 50 documents and 30 recipients.</li></ul>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree33">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseThree33"
                                                        aria-expanded="false" aria-controls="collapseThree33">
                                                    Does DocuTick integrate with any cloud applications? 
                                                </button>
                                            </h2>
                                            <div id="collapseThree33" className="accordion-collapse collapse"
                                                 aria-labelledby="headingThree33" data-bs-parent="#accordionExample3">
                                                <div className="accordion-body">
                                                Yes, DocuTick integrates with popular cloud storage services such as Google Drive, OneDrive, Dropbox, and Box, allowing for seamless document management and storage.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour44">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseFour44"
                                                        aria-expanded="false" aria-controls="collapseFour44">
                                                    Can I get a refund after buying a plan? 
                                                </button>
                                            </h2>
                                            <div id="collapseFour44" className="accordion-collapse collapse"
                                                 aria-labelledby="headingFour44" data-bs-parent="#accordionExample3">
                                                <div className="accordion-body">
                                                Unfortunately, DocuTick does not offer refunds after purchasing a plan.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFive55">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseFive55"
                                                        aria-expanded="false" aria-controls="collapseFive55">
                                                    How do I upgrade my plan? 
                                                </button>
                                            </h2>
                                            <div id="collapseFive55" className="accordion-collapse collapse"
                                                 aria-labelledby="headingFive55" data-bs-parent="#accordionExample3">
                                                <div className="accordion-body">
                                                You can easily upgrade your plan by logging in to your account, navigating to the "Billing" section, and selecting the desired plan upgrade option.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFive66">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseFive66"
                                                        aria-expanded="false" aria-controls="collapseFive66">
                                                    How do I cancel my plan? 
                                                </button>
                                            </h2>
                                            <div id="collapseFive66" className="accordion-collapse collapse"
                                                 aria-labelledby="headingFive66" data-bs-parent="#accordionExample3">
                                                <div className="accordion-body">
                                                To cancel your subscription, log into your DocuTick account, go to the Billing section, and select Cancel Subscription.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane bg_transparent " id="integration" role="tabpanel"
                                     aria-labelledby="integration-tab">
                                    <div className="accordion" id="accordionExample4">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading111">
                                                <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapse111"
                                                        aria-expanded="true" aria-controls="collapse111">
                                                    How secure is my data with DocuTick?
                                                </button>
                                            </h2>
                                            <div id="collapse111" className="accordion-collapse collapse"
                                                 aria-labelledby="heading111" data-bs-parent="#accordionExample4">
                                                <div className="accordion-body">
                                                DocuTick uses bank-grade encryption for data protection and includes a secure, passcode-protected client portal. Two-Factor Authentication (2FA) adds an additional layer of protection. The platform also offers a detailed audit trail to track all actions, ensuring complete transparency and security.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Faq;