import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import ProductFeatures from "../partials/ProductFeatures";
import Testimonial from "../partials/Testimonial";
import {Lang} from "../../../lang";

function HomePage() {
    const navigate = useNavigate();

    document.title = Lang.homepage_title;
    document.getElementsByTagName("META")[4].content = Lang.homepage_meta_desc;
    document.getElementsByTagName("META")[5].content = Lang.homepage_meta_keyword;

    const handleSignUp = (e) => {
        e.preventDefault();
        navigate('/sign-up');
    };

    return (
        <div className="p-0" style={{minHeight: 'calc(100vh - 401px)'}}>
            <div className="container-fluid" style={{maxWidth: '1600px', width: '100%'}}>
                <div className="row py-5">
                    <div className="col-md-12  d-flex align-items-center justify-content-center  my-4 my-md-0">
                        <div className=" flex-column ms-xxl-0 d-flex align-items-center docutik_home_banner">
                            <h1 className="text-dark text-center  mb-5"><span
                                className="text-primary d-block">Effortless Document, Information<br></br> and E-Signature Collection -</span> Simplified for You.

 
                            </h1>
                            <ul className="checked_section mb-4 text-dark">
                                <li className="mb-3"><i className="fa fa-check" aria-hidden="true"/>Effortless Document, Information, and E-Signature Request
                                </li>
                                <li className="mb-3"><i className="fa fa-check" aria-hidden="true"/>Efficiently Approve or Reject Documents and Information
                                </li>
                                <li className="mb-3"><i className="fa fa-check" aria-hidden="true"/>Client Portal with Passcode-Protected Access
                                </li>
                                
                            </ul>

                            <h3 className="text-dark text-center  mb-4"><span
                                className="text-success d-block">3 Requests Free Per Month</span>

                            </h3>

                            <div
                                className="d-flex flex-wrap align-items-center justify-content-center get_started mb-5">
                                <button type="button" onClick={handleSignUp}
                                        className="btn book-now bg-warning text-black fw-bold fs-5">Get Started
                                </button>
                            </div>
                            <div
                                className="d-flex flex-wrap align-items-center justify-content-center get_started_video mb-5">
                                <iframe src="https://www.youtube.com/embed/oBwpG_t_a7g"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen/>
                            </div>
                            <ul className="box_ul">
                                <li className="mb-3 text-success">Collect Documents 85% faster from your clients</li>
                                <li className="mb-3 text-success">No Credit Card Required, Register Now</li>
                                <li className="mb-3 text-success">Collect Documents, Information & E-Signature</li>
                                <li className="mb-3 text-success">Efficiently Approve or Reject Documents and Information
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 mt-5 text-center">
                        <h1 className="text-dark text-center mb-2">Empower Your Workflow with DocuTick's Trusted Solutions!</h1>
                    </div>
                </div>
            </div>
            <div className="custom_container container tabing_client_portal">
                <div className=" rounded-5 home-page gradient-bg-div">
                    <div className="row my-5 px-2 px-sm-3 px-lg-5 py-sm-4 py-lg-5 py-4 ">
                        <div className="col-lg-12 d-flex align-items-start justify-content-center">
                            <div className="py-3">
                                <ul className="nav nav-tabs justify-content-sm-start  justify-content-lg-between w_42">
                                    <li className="nav-item mx-2" style={{listStyle: 'none'}}>
                                        <a data-bs-toggle="tab" href="#InvitePage"
                                           className="nav-link active text-dark px-0">
                                            <b>Request Page</b>
                                        </a>
                                    </li>
                                    <li className="nav-item mx-2" style={{listStyle: 'none'}}>
                                        <a data-bs-toggle="tab" href="#ClientPorta"
                                           className="nav-link  text-dark px-0">
                                            <b>Client Portal</b>
                                        </a>
                                    </li>
                                    <li className="nav-item mx-2" style={{listStyle: 'none'}}>
                                        <a data-bs-toggle="tab" href="#CheckingPage"
                                           className="nav-link  text-dark px-0">
                                            <b>Manage Page</b>
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content mt-5">
                                    <div id="InvitePage" className="tab-pane  active">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <h2 className="text-dark text-center text-sm-start mb-3">Request Documents, Information and E-Signature Effortlessly</h2>
                                                <p className="text-center text-center text-sm-start">Simplify the way you gather documents, data, and e-signatures from your clients or team. With DocuTick, you can easily send requests, track responses in real-time, and ensure everything is securely collected in one place, saving you time and reducing paperwork.</p>
                                                <button type="button" onClick={handleSignUp}
                                                        className="btn book-now bg-primary text-white my-4 d-flex mx-auto mx-sm-0">
                                                    Register Free
                                                </button>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="image_wrap">
                                                    <img src="/images/home-1.png" alt="..."/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="ClientPorta" className="tab-pane ">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <h2 className="text-dark text-center text-sm-start mb-3">Secure Client Portal for Hassle-Free Collaboration</h2>
                                                <p className="text-center text-center text-sm-start">Give your clients peace of mind with a secure, passcode-protected portal where they can safely upload documents, share information, and sign agreements. With real-time updates and seamless access, managing client collaboration has never been easier or more secure.</p>
                                                <button type="button" onClick={handleSignUp}
                                                        className="btn book-now bg-primary text-white my-4 d-flex mx-auto mx-sm-0">
                                                    Register Free
                                                </button>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="image_wrap bg">
                                                    <img src="/images/client-portal.png" alt="..."
                                                         className="shadow-sm"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="CheckingPage" className="tab-pane">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <h2 className="text-dark text-center text-sm-start mb-3">Effortlessly Manage Documents and Workflows</h2>
                                                <p className="text-center text-center text-sm-start">Simplify your workflow with our comprehensive management tools. Track document submissions, approvals, and e-signatures in real-time, ensuring nothing falls through the cracks. With DocuTick, managing tasks, deadlines, and client requests has never been smoother or more efficient.</p>
                                                <button type="button" onClick={handleSignUp}
                                                        className="btn book-now bg-primary text-white my-4 d-flex mx-auto mx-sm-0">
                                                    Register Free
                                                </button>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="image_wrap bg">
                                                    <img src="/images/home-3.png" alt="..." className="shadow-sm"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ProductFeatures/>

            <div className="custom_container-fluid product py-5" style={{backgroundColor: '#f6f9fc'}}>
                <h1 className="text-dark text-center mb-2">How it Works</h1>
                <h3 className="text-primary text-center mb-2">3 Envelopes Free / Month</h3>
                <p className="text-center mb-5">Docutick simplifies document management, information collection, and digital signatures with a streamlined process</p>
                <div className="custom_container">
                    <div className="row py-4">
                        <div className="col-md-6 ">
                            <div className="mb-3 mb-md-0 image_wrap">
                                <img src="/images/product-1.png" alt="..."/>
                            </div>
                        </div>
                        <div className="col-md-6 d-md-flex justify-content-end align-items-center">
                            <div className="">
                               
                                <h2 className="text-dark text-center text-md-start  mb-4 mb-md-4">Step 1: Create Your Envelope</h2>
                                <p className="mb-0 mb-md-5 text-center text-md-start">Start by creating a new envelope from scratch or using a premade template. Customize it with specific document, information, and signature requests.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="col-md-6 order-2 order-md-1 d-md-flex justify-content-start align-items-center">
                            <div className="">
                                
                                <h2 className="text-dark text-center text-md-start  mb-4 mb-md-4">Step 2: Client Portal Access</h2>
                                <p className="mb-0 mb-md-5 text-center text-md-start">Clients securely access the portal with a passcode to upload documents, provide information, and sign digitally, ensuring a smooth and secure process.</p>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2">
                            <div className="mb-3 mb-md-0 image_wrap">
                                <img src="/images/product-4.png" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="col-md-6 ">
                            <div className="mb-3 mb-md-0 image_wrap">
                                <img src="/images/product-2.png" alt="..."/>
                            </div>
                        </div>
                        <div className="col-md-6 d-md-flex  justify-content-end align-items-center">
                            <div className="">
                                
                                <h2 className="text-dark text-center text-md-start  mb-4 mb-md-4">Step 3: Track and Manage</h2>
                                <p className="mb-0 mb-md-5 text-center text-md-start">Monitor responses in real-time, approve or reject submissions, and request re-submissions as needed, all within the platform.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="col-md-6 order-2 order-md-1 d-md-flex justify-content-start align-items-center">
                            <div className="">
                                
                                <h2 className="text-dark text-center text-md-start  mb-4 mb-md-4">Step 4: Secure Storage
                                    Finalize</h2>
                                <p className="mb-0 mb-md-5 text-center text-md-start">Store all documents securely and integrate with cloud services like Google Drive, OneDrive, Dropbox, or Box for easy access.</p>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2">
                            <div className="mb-3 mb-md-0 image_wrap">
                                <img src="/images/product-3.png" alt="..."/>
                            </div>
                        </div>
                    </div>

                    <div className="row py-4">
                        <div className="col-md-6 ">
                            <div className="mb-3 mb-md-0 image_wrap">
                                <img src="/images/product-2.png" alt="..."/>
                            </div>
                        </div>
                        <div className="col-md-6 d-md-flex  justify-content-end align-items-center">
                            <div className="">
                                
                                <h2 className="text-dark text-center text-md-start  mb-4 mb-md-4">Step 5: Save as Templates</h2>
                                <p className="mb-0 mb-md-5 text-center text-md-start">Save envelopes as templates for future use, streamlining repeat tasks and maintaining consistency.</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="container custom_container py-5 use-cases-section bg-white">
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <h2 className="text-dark text-center mb-5">USE CASES</h2>
                    
                    <p className="text-center" style={{maxWidth: '718px', width: '100%'}}>With DocuTick, everything is simplified. You can manage all your document needs in one place, saving time and reducing stress. Whether it's collecting documents, gathering information, or securing e-signatures, DocuTick streamlines your workflow so you can focus on what really matters. It’s paperless, efficient, and designed to make your life easier.</p>
                </div>
                <div className="row gx-2 gx-lg-5 gy-4 my-5">
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/trader.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Accounting & Bookkeeping</h4>
                                <p>Streamline document collection for financial statements and receipts with DocuTick. Easily gather data and secure e-signatures on critical bookkeeping tasks.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/accounting.svg " alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Taxation</h4>
                                <p>Simplify tax document collection and gather accurate data from clients with ease. Quickly secure e-signatures on filings and forms for faster processing.

</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/employment.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Auditing</h4>
                                <p>DocuTick helps you collect audit documents and critical data in a secure, organized way. Obtain legally binding e-signatures on reports to streamline compliance.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/passport.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Mortgages</h4>
                                <p>Collect all necessary mortgage documents and borrower data without the hassle. Use e-signatures to finalize agreements quickly and securely.

</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/graduation-cap.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Lending & Brokerage</h4>
                                <p>Effortlessly collect loan documents and financial data with DocuTick’s streamlined platform. Finalize contracts with e-signatures to expedite client transactions.

</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/audit.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Real Estate</h4>
                                <p>Manage property documents and gather essential data from buyers or sellers in one place. E-signature functionality speeds up contract approval and closings.</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/audit.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Immigration</h4>
                                <p>DocuTick simplifies the collection of immigration documents and client data for faster case processing. Securely gather e-signatures on visa applications and forms.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/audit.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Insurance</h4>
                                <p>Automate document collection for claims and policies, and easily gather client data. Use e-signatures for swift approval on policy agreements and settlements.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/audit.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Legal Services</h4>
                                <p>Manage client agreements and collect legal documents with ease. Secure digital signatures on contracts and ensure all legal processes are compliant.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/audit.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Financial Services</h4>
                                <p>Collect critical financial documents and data securely through DocuTick. Finalize agreements with e-signatures to maintain compliance and speed up transactions.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/audit.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Education</h4>
                                <p>Simplify admissions and enrollment by collecting student documents and data in one platform. Use e-signatures to finalize forms for faster processing.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/audit.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Human Resources</h4>
                                <p>HR teams can collect employee records and personal data efficiently with DocuTick. Secure e-signatures on contracts and policy acknowledgments in seconds.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/audit.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Digital Agencies</h4>
                                <p>Collect project briefs and client data seamlessly. Obtain e-signatures on service agreements to kickstart projects quickly and securely.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '70px'}}>
                                <div
                                    className="mb-4 bg-primary  rounded-circle d-flex align-items-center justify-content-center "
                                    style={{height: '63px', width: '63px'}}>
                                    <img src="/images/audit.svg" alt="..." style={{maxWidth: '60%'}}/>
                                </div>
                            </div>
                            <div className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 70px)'}}>
                                <h4 className="text-dark mb-2">Administration</h4>
                                <p>Centralize document and data collection for internal operations with ease. Use e-signatures to secure approvals and keep administrative workflows moving efficiently.</p>
                            </div>
                        </div>
                    </div>

                    


                </div>
                <p className="text-center">
                    <NavLink to={'/use-cases'} className="text-primary">
                        <b>Learn More <i className="fa fa-long-arrow-right" aria-hidden="true"/></b>
                    </NavLink>
                </p>
            </div>

            <div className="container-fluid product py-5" style={{backgroundColor: '#f6f9fc'}}>
                <div className="custom_container">
                    <div className="row rounded-4 overflow-hidden">
                        <div className="col-md-6 p-lg-5 p-3 pt-5" style={{backgroundColor: '#2b84fc'}}>
                            <h2 className="text-white  mb-4">Traditional Way</h2>
                            
                            <div className="row gx-2 gx-lg-5 gy-4 my-3">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className="mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Manual Document Collection</h4>
                                            <p className="text-white">Collecting documents & Data manually through emails is time-consuming and prone to errors.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Paper-Based Workflows</h4>
                                            <p className="text-white">Traditional methods involve a lot of paperwork, leading to clutter and inefficiency.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Chasing Clients for Documents</h4>
                                            <p className="text-white">Manually following up for document submissions wastes valuable time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">No Centralized System</h4>
                                            <p className="text-white">Traditional methods scatter documents across emails and folders.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Risk of Human Error</h4>
                                            <p className="text-white">Human error in manual data entry and document management can cause delays.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">In-Person Signatures Required</h4>
                                            <p className="text-white">Getting physical signatures can be slow and cumbersome, especially with remote clients.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Limited Transparency</h4>
                                            <p className="text-white">It's hard to track the status of documents in traditional processes.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Slow Turnaround Times</h4>
                                            <p className="text-white">Manual methods slow down the workflow, affecting project timelines.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">No Automated Reminders</h4>
                                            <p className="text-white">Traditional methods rely on manual follow-ups for deadlines.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Lack of Secure Client Access</h4>
                                            <p className="text-white">Providing secure access to clients for document exchange can be difficult.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">No Cloud Storage Integration</h4>
                                            <p className="text-white">Documents stored in different locations can lead to inefficiencies.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">No Standardized Processes</h4>
                                            <p className="text-white">Creating new document requests from scratch for every task is time-consuming.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-cancel.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Scattered Communication</h4>
                                            <p className="text-white">Email-based communications can become disorganized, making it hard to track conversations.</p>
                                        </div>
                                    </div>
                                </div>





                            </div>
                        </div>
                        <div className="col-md-6 p-lg-5 p-3 pt-5  bg-primary">
                            <h2 className="text-white  mb-4">DocuTick Way</h2>
                            
                            <div className="row gx-2 gx-lg-5 gy-4 my-3">
                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Automated Document Collection</h4>
                                            <p className="text-white">With DocuTick, document and data collection is automated, allowing you to send customized requests and track submissions in real-time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Paperless and Digital Workflows</h4>
                                            <p className="text-white">DocuTick promotes a fully digital, paperless environment that streamlines workflows and reduces clutter.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Automated Reminders</h4>
                                            <p className="text-white">DocuTick sends automated reminders to clients, ensuring timely document submissions without manual effort.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Centralized and Organized</h4>
                                            <p className="text-white">DocuTick centralizes all documents and data in one secure platform, making them easily accessible and organized.</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Minimized Errors</h4>
                                            <p className="text-white">DocuTick reduces errors with automated workflows, ensuring that documents and data are collected correctly.</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">E-Signature Feature</h4>
                                            <p className="text-white">DocuTick allows secure, legally binding e-signatures, speeding up approval processes.</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Real-Time Tracking</h4>
                                            <p className="text-white">DocuTick offers real-time tracking, giving full visibility into document submissions and approvals.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Faster Turnaround</h4>
                                            <p className="text-white">DocuTick accelerates document collection and approvals, enabling faster project completion.</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Automated Alerts and Notifications</h4>
                                            <p className="text-white">DocuTick automatically sends alerts and notifications, helping ensure that deadlines are met.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Secured Client Portal</h4>
                                            <p className="text-white">DocuTick offers a secure, password-protected client portal for seamless and safe document sharing.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Cloud Storage Integration</h4>
                                            <p className="text-white">DocuTick integrates with cloud storage platforms like Google Drive, Dropbox, OneDrive, and Box, centralizing all files.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Reusable Templates</h4>
                                            <p className="text-white">DocuTick allows you to create reusable templates for common tasks, ensuring consistency and efficiency across all projects.</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-12">
                                    <div className="d-flex">
                                    <div className="" style={{width: '60px',minHeight: '100px'}}>
                                            <img src="/images/home-true.svg" alt="..." style={{maxWidth: '70%'}}/>
                                        </div>
                                        <div
                                            className=" mx-3 d-flex align-items-start justify-content-center flex-column"
                                            style={{width: 'calc(100% - 60px)',minHeight: '100px'}}>
                                            <h4 className="text-white mb-2">Messaging System</h4>
                                            <p className="text-white">DocuTick’s built-in messaging system keeps all client interactions organized and within the platform for easy reference.</p>
                                        </div>
                                    </div>
                                </div>





                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <h2 className="text-dark text-center my-4 ">3 Envelopes Free/Month</h2>
                        <button onClick={handleSignUp} type="button" className="btn book-now bg-primary text-white px-3 py-2">
                            Register Now
                        </button>
                    </div>
                </div>
            </div>

            <div className="container py-5">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center flex-column">
                        <div
                            className="d-flex  align-items-center align-items-md-start justify-content-center flex-column">
                            <div className="px-3 py-2 mb-3 rounded-3"
                                 style={{backgroundColor: '#e6f0ff', width: 'fit-content'}}>
                                <p className="text-dark text-center text-md-start"><b>INTEGRATION</b></p>
                            </div>
                            <h2 className="text-dark my-4 display-5 text-center text-md-start">Effortless Cloud Integration for Document Sync and Storage</h2>
                            <p className="mb-3 text-center text-md-start">With DocuTick's cloud integration, all your documents are securely synced and stored on leading platforms like Google Drive, OneDrive, Dropbox, and Box. Easily manage, access, and back up your important files directly from the cloud, ensuring smooth collaboration and streamlined workflows across your organization.</p>
                            <NavLink to={"/integration"} className="text-primary">
                                <b>Learn More <i className="fa fa-long-arrow-right" aria-hidden="true"/></b>
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="image_wrap">
                            <img src="/images/home-2.png" alt="..."/>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial/>

            <div className="container custom_container py-5 use-cases-section bg-white">
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <h2 className="text-dark text-center ">Have Question?</h2>
                </div>
                <div className="row gx-2 gx-lg-5 gy-4 my-5">
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '20px'}}>
                                <img src="/images/questionmark.svg" alt="..."/>
                            </div>
                            <div className=" mx-2 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 20px)'}}>
                                <h5 className="text-dark mb-2">How do I sign up for DocuTick? </h5>
                                <p>You can sign up by visiting the DocuTick website and clicking on the “Get Started” or “Free Register” button. Simply follow the steps to create your account using your email. No credit card is needed for setup, and you will receive 3 free envelopes per month with the free plan.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '20px'}}>
                                <img src="/images/questionmark.svg" alt="..."/>
                            </div>
                            <div className=" mx-2 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 20px)'}}>
                                <h5 className="text-dark mb-2">What is an Envelope in DocuTick?</h5>
                                <p>An Envelope in DocuTick is a set of document or information requests sent to one or multiple recipients. You can use an Envelope to request documents, information, or e-signatures from others in one organized batch.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '20px'}}>
                                <img src="/images/questionmark.svg" alt="..."/>
                            </div>
                            <div className=" mx-2 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 20px)'}}>
                                <h5 className="text-dark mb-2">What are Templates in DocuTick? </h5>
                                <p>Templates in DocuTick allow you to reuse pre-configured document requests for future use. This is useful for processes you run regularly, such as requesting similar documents from multiple clients.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="" style={{width: '20px'}}>
                                <img src="/images/questionmark.svg" alt="..."/>
                            </div>
                            <div className=" mx-2 d-flex align-items-start justify-content-center flex-column"
                                 style={{width: 'calc(100% - 20px)'}}>
                                <h5 className="text-dark mb-2">How secure is DocuTick? </h5>
                                <p>DocuTick uses bank-grade encryption for data protection and includes a secure, passcode-protected client portal. Two-Factor Authentication (2FA) adds an additional layer of protection. The platform also offers a detailed audit trail to track all actions, ensuring complete transparency and security.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="text-center">
                    <NavLink to={"/faq"} className="text-primary">
                        <b>Learn More <i className="fa fa-long-arrow-right" aria-hidden="true"/></b>
                    </NavLink>
                </p>
            </div>
            <div className="custom_container my-5">
                <div className="  px-4 py-lg-5 py-5  border_radius  gradient-bg-div text-center">
                    <h2 className="text-dark me-4 text-center mb-3">Trusted by Businesses Everywhere – Try DocuTick Today!</h2>
                    <p className="mb-3 text-dark">No credit card | Cancel at any time</p>
                    <h2 className="text-primary me-4 text-center mb-3">3 Envelopes Free/Month</h2>
                    <button onClick={handleSignUp} type="button" className="btn book-now bg-primary text-white py-2 px-4">
                        Register Now
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HomePage;